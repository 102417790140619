<template>
  <div id="logout-modal" >
    <img src="/img/btns/loading.svg" width="200" height="200" alt="" title=""/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "logout",
  computed: {

  },     
  data() {
    return {

    }
  },
  methods: {
    
  },
  mounted(){
    this.logout();
    this.tracking({
        type: 'page',
        title: '/logout',
    });     
    //this.$router.push('/');   
  }  
}
</script>

<style lang="scss" scoped>
#logout-modal {
  padding: 100px 100px;
  text-align: center;

}
</style>