<template>
    <div id="signup-onboarding">
        <h1>{{$t('signup.onboarding.heading')}}</h1>
        <b-row>
          <b-col>
            <img src="/img/elements/auth/blue_guy_solo.png" width="330" height="290" alt="blue alien solo" title="blue alien solo"/>
            <h2>{{$t('signup.onboarding.playsolo')}}</h2>
          </b-col>
          <b-col>
            <img src="/img/elements/auth/friends.png" width="330" height="290" alt="aliens together" title="aliens together"/>
            <h2>{{$t('signup.onboarding.playtogether')}}</h2>
          </b-col>          
        </b-row>
        <br/><br/><br/>
        <div id="button-container">
          <b-button pill variant="primary" @click="continueNext()">
            <div class="continue-icon ps-icons"></div>
            {{$t('global.readysetgo')}}
          </b-button>
        </div>
    </div>
</template>

<script>
export default {
  name: "SignupOnbaording",  
  mounted(){
    this.tracking({
        type: 'page',
        title: '/signup/onboarding',
    });   
  },
  methods: {
    continueNext(){
      this.$router.push('/home');
       this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'signup:continue',
      });   
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/scss/variables';
#signup-onboarding{
  position: relative;
  h1 {
    text-align: center;
  }
  h2 {
    width: 290px;
  }
  #button-container {
    margin: 0 auto;
    width: 180px;
  }
}
#signup-thanks {
  position: absolute;
  top: 55px;
  left: 45px;
  z-index: 100;
  width: 237px;
  color: $primary;
  font-size: 18px;
  line-height: 27px;
}
</style>