<template>
    <div id="avatar-selector">
        <p>{{$t('signup.step2.chooseavatar')}}</p>
        <div class="avatar-block d-inline-block">
          <div class="avatar-container avatar-lg avatar">
            <img class="avatar-img" id="avatar-selected" :src="selectedAvatar" width="100" height="100" />
            <div id="avatar-name">{{firstName}}</div>
          </div>            
        </div>
        <div class="avatar-list-block d-inline-block">
          <b-row id="avatar-list">
            <b-col v-for="avatar in avatars" :key="avatar.id" class="avatar-col">
              <div @click="setAvatar(avatar.asset,avatar.name)" class="avatar-container avatar-sm avatar">
                <img v-bind:id="avatar.name" class="avatar-img" v-bind:src="avatar.asset" width="40" height="40" />
              </div>
            </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>
import Avatars from '@/data/avatars.json'
export default {
  name: "Avatars", 
  props: {
    avatarPhoto: String,
    firstName: String,
    section: String
  },
  watch: {
    'avatarPhoto': {
      handler (newVal) {
        if (newVal) { 
          this.selectedAvatar = this.avatarPhoto;
        }
      },
      immediate: true 
    }
  },  
  data() {
    return {
      avatars: Avatars,
      selectedAvatar: this.avatarPhoto
    };
  },
  methods: {
     setAvatar(path,name) {
      this.selectedAvatar = path
      //document.getElementById('avatar-selected').src = path;
      this.$emit('changeAvatar',path);
      this.tracking({
        type: 'event',
        category: this.section,
        action: 'avatar:select',
        label: name
      });        
    }     
  }
};
</script>
<style lang="scss" scoped>
#avatar-selector {
  .avatar-block {
    vertical-align: top;
    width: 160px;
  }  
  .avatar-list-block {
    vertical-align: top;
    width: 530px;    
  }
  #avatar-name {
    font-size: 14px;
    text-align: center;
    width: 140px;
    margin: 6px 0 0 0;
    text-transform: capitalize;
  }
  #avatar-list {
    max-width: 575px;  
    .avatar-col {
      margin: 0 0 0 20px;
      width: 50px;
    }
  }
}


</style>