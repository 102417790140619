<template>
  <div id="signup-step-1">
    <!--div id="back-button">
      <router-link to="/signup"><img src="/img/btns/back.svg" width="13" height="24"/></router-link>
    </div-->
    <b-container fluid="md">
    <b-row align-h="start">
      <b-col cols="12">
        <h1>{{$t('signup.step1.heading')}}</h1>     
        <div v-if="error" class="error-msg">{{error}}</div>
        <br/>
      </b-col>
    </b-row>
    <form @submit.prevent>
      <b-row align-h="start" v-if="showInviteCode == true">
        <b-col cols="12" md="6">   
          <label for="invitecode">{{$t('signup.step1.invitecode')}}<!--span class="required">{{$t('signup.step1.fromemail')}}</span--></label>
          <input
          v-model.trim="signupform.invitecode"
          id="invitecode"
          name="invitecode"
          type="text"
          class="form-control"
          required
          autofocus
          tabindex="1"
          @click="trackInput('invitecode')"
          />
        </b-col>
      </b-row>
      <b-row align-h="start">
        <b-col cols="12" md="6">                  
          <label for="firstname" class="">{{$t('signup.step1.firstname')}}</label>       
          <input
          v-model.trim="signupform.firstname"
          id="firstname"
          name="firstname"
          maxlength=30
          type="text"
          class="form-control"
          required
          tabindex="2"
          @click="trackInput('firstname')"
          />
        </b-col>
        <b-col>
          <label for="lastname" class="">{{$t('signup.step1.lastname')}}</label>
          <input
          v-model.trim="signupform.lastname"
          maxlength=100
          id="lastname"
          name="lastname"
          type="text"
          class="form-control"
          required
          tabindex="3"
          @click="trackInput('lastname')"
          />          
        </b-col>
        <b-col cols="12" md="6">   
          <label for="email" class="">{{$t('signup.step1.email')}}</label>
          <input
          v-model.trim="signupform.email"
          maxlength=320
          id="email"
          name="email"
          type="text"
          class="form-control"
          required
          tabindex="4"
          @click="trackInput('email')"
          />
        </b-col>
        <b-col>          
          <label for="password" class="">{{$t('signup.step1.password')}}</label>
          <input
          v-model.trim="signupform.password"
          maxlength=50
          id="password"
          name="password"
          type="password"
          class="form-control"
          required
          tabindex="5"
          @click="trackInput('password')"
          />                
        </b-col>
      </b-row>
      <br/>
      <b-row align-v="center" align-h="start">
        <b-col cols="12" md="10"><div class="custom-checkbox">
          <input
          v-model.trim="signupform.tosaccept"
          id="tosaccept"
          name="tosaccept"
          type="checkbox"
          required
          tabindex="6"
          @click="trackInput('tcs')"
          />
          <label for="tosaccept" class="label-lighter">
            {{$t('signup.step1.termsAccept.iaccept')}}<a href="https://www.playspaces.co/terms-of-use" target="_blank">{{$t('signup.step1.termsAccept.tos')}}</a>{{$t('signup.step1.termsAccept.and')}}<a href="https://www.playspaces.co/privacy-policy" target="_blank">{{$t('signup.step1.termsAccept.privacy')}}</a>{{$t('signup.step1.termsAccept.endagree')}}
          </label>
          </div>
          <br/><br/> 
        </b-col>  
      </b-row>
      <!--b-row align-v="center" align-h="start">        
        <b-col cols="12" md="12"><div class="custom-checkbox">
          <input
          v-model.trim="signupform.newsletter"
          id="newsletter"
          name="newsletter"
          type="checkbox"
          tabindex="7"
          @click="trackInput('newsletter')"
          />
          <label for="newsletter" class="label-lighter">{{$t('signup.step1.newslettersignup')}}</label> 
          </div>
        </b-col>  

      </b-row-->
      <br/><br/>
      <b-row align-v="center" align-h="start">
        <b-col cols="12" md="3"> 
          <b-button pill variant="primary" @click="checkForm()" v-if="saving == false">
            <div class="continue-icon ps-icons"></div>
            {{$t('global.continue')}}
          </b-button> 
          <b-button pill variant="secondary" v-else>
            <div class="loading-icon ps-icons"></div>
            {{$t('global.saving')}}
          </b-button>               
        </b-col>
        <b-col cols="12" md="9" class="button-right"> 
          <p>{{$t('signup.step1.alreadyhaveaccount')}}<a @click.prevent="login()" href="#">{{$t('signup.step1.logmein')}}</a>.</p>
        </b-col>
      </b-row>
      
    </form>    
    </b-container>      
  </div>  
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex'
export default {
  name: "SignupStep1",  
  computed: {
    ...mapState(['userData']), 
    ...mapState(['guestData']), 
    ...mapState(['signUpCodeData']),  
  },      
  data() {
    return {
      signupform: {
        invitecode: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        tosaccept: false,
        newsletter: false,
        country: '',
        timezone: '',
        language: 'en',
        referralId: null,
        origin: 'unknown',         
      },
      error: null,
      type: null,
      saving: false,
      showInviteCode: true
    };
  },
  methods: {
    checkForm() {
      this.error = null;
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'signup:complete',
      });   
      if(!this.signupform.invitecode) {
        this.error = this.$t('errors.invitecodemissing');
        return;
      }     
      if(!this.signupform.firstname){
        this.error = this.$t('errors.firstname');
        return;
      } 
      if(!this.signupform.lastname){
        this.error = this.$t('errors.lasttname');
        return;
      }  
      if(!this.validPassword(this.signupform.password)){
        this.error = this.$t('errors.validpassword');
        return;
      }            
      if(!this.validEmail(this.signupform.email)){
        this.error = this.$t('errors.validemail');
        return;
      } 
      if(!this.signupform.tosaccept){
        this.error = this.$t('errors.mustaccepttos');
        return;
      } 
      this.saving = true;
      this.sendit();
    },
    async sendit() {
      try {
        this.$store.dispatch('addUser', {
          invitecode: this.signupform.invitecode,
          email: this.signupform.email,
          password: this.signupform.password,
          firstname: this.signupform.firstname,
          lastname: this.signupform.lastname,
          tosaccept: this.signupform.tosaccept,
          newsletter: this.signupform.tosaccept,
          country: this.signupform.country,
          timezone: this.signupform.timezone,
          language: this.signupform.language,
          type: this.type,
          referralId: this.signupform.referralId,
          origin: this.signupform.origin
        })
        .then((results) => {
          if(results.status == 400) {
            this.saving = false;
            this.error = results.data;
          } else {
            if(this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push('/signup/step2')
            }
          }
        })
      }
      catch (error) {
        this.saving = false;
        this.error = error.message;
      }
 
    },
    trackInput(label){
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'signup:forminput',
        label: label
      });     
    },
    login() {
      this.$router.push('/login');
       this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'signup:hasaccount',
      });      
    },
    formSetup() {
      if(this.$route.query.type){
        this.type = this.$route.query.type;
      } else {
        this.type = null;
      }
      if(this.guestData != null) {
        // If they are a guest this disables invite code and auto populates their email.
        if(this.guestData.guest.email != null) {
          document.getElementById('email').disabled = true;
          document.getElementById('invitecode').disabled = true;
          this.signupform.email = this.guestData.guest.email;
          this.signupform.firstname = this.guestData.guest.name;
          // TODO add userProfileId for host that invited them.
          this.signupform.referralId = this.guestData.host.userId;
          this.signupform.invitecode = this.guestData.guest.inviteCode;
          this.signupform.origin = "privateBetaPlaydate";
          this.showInviteCode = false;
        }
      } else if (this.signUpCodeData != null){
        document.getElementById('email').disabled = true;
        document.getElementById('invitecode').disabled = true;
        this.signupform.email = this.signUpCodeData.email;
        this.signupform.firstname = this.signUpCodeData.firstname;
        this.signupform.lastname = this.signUpCodeData.lastname;
        this.signupform.referralId = null; 
        this.signupform.invitecode = this.signUpCodeData.id;
        this.signupform.origin = "privateBetaScreener"
        this.showInviteCode = false;           
      }
      this.signupform.timezone = this.$store.getters.currentTimezone;
      this.signupform.country = this.$store.getters.currentLocation;
    }
  },
  mounted () {
    this.formSetup();
    this.tracking({
        type: 'page',
        title: '/signup/step1',
    });        
  }
};
</script>    

<style lang="scss">
#signup-step-1{
  position: relative;
  h2{
    margin: 26px 0 0 0;
  }
  #back-button {
    position: absolute;
    left: -50px;
    top: 26px;
    z-index: 100;
  }
}
</style>