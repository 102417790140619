<template>
    <div id="signup-step-2">
        <h1>{{$t('signup.step2.heading')}}</h1>
        <p>{{$t('signup.step2.subheading')}}</p>
        <div v-if="error" class="error-msg">{{error}}</div>
        <form @submit.prevent >
        <br/>
          <AvatarsList 
            v-bind:avatarPhoto="profileform.profilePhoto" 
            v-bind:firstName="profileform.firstname" 
            v-bind:section="trackingsection"
            @changeAvatar="changeAvatarPic($event)"
          />
          <br/><br/>
          <b-row v-if="individualOnly == false">
            <b-col cols="3">
              <div class="input-label">
                <label for="pin1">{{$t('signup.step2.setpin')}}</label>
              </div>
            </b-col>
            <b-col cols="9" >
              <div class="input-wrapper d-inline-block">
                    <div id="parental-pin">
                      <input
                      v-model.trim="profileform.pin.firstnumber" 
                      class="digits"
                      type="text" 
                      maxlength=1
                      id="firstnumber" 
                      name="firstnumber"
                      required
                      autofocus
                      tabindex="1"    
                      v-on:keyup.enter="$event.target.nextElementSibling.focus()"
                      @click="trackInput('pin-1')"
                      />
                      <input
                      v-model.trim="profileform.pin.secondnumber" 
                      class="digits"
                      type="text" 
                      maxlength=1
                      id="secondnumber" 
                      name="secondnumber"
                      required
                      autofocus
                      tabindex="2"
                      v-on:keyup.enter="$event.target.nextElementSibling.focus()"
                      @click="trackInput('pin-2')"
                      />
                      <input
                      v-model.trim="profileform.pin.thirdnumber" 
                      class="digits"
                      type="text" 
                      maxlength=1
                      id="thirdnumber" 
                      name="thirdnumber"
                      required
                      autofocus
                      tabindex="3"
                      v-on:keyup.enter="$event.target.nextElementSibling.focus()"
                      @click="trackInput('pin-3')"
                      /> 
                      <input
                      v-model.trim="profileform.pin.forthnumber" 
                      class="digits"
                      type="text" 
                      maxlength=1
                      id="forthnumber" 
                      name="forthnumber"
                      required
                      autofocus
                      tabindex="4"
                      v-on:keyup.enter="$event.target.nextElementSibling.focus()"
                      @click="trackInput('pin-4')"
                      />
                    </div>  
              </div>
              <div class="important-bubble d-inline-block">!</div>
              <div class="important-info d-inline-block">
                <p class="ps-light-weight">{{$t('signup.step2.pindetails')}}</p>           
              </div>   
            </b-col>                   
          </b-row> 
          <br/>
          <b-row>
            <b-col cols="3">
              <div class="input-label">
                <label for="pin1">{{$t('signup.step2.birthday')}}</label>
              </div>
            </b-col>            
            <b-col cols="9">
              <div class="input-wrapper d-inline-block">
                <input
                v-model.trim="profileform.birthday.month" 
                class="form-control birthday-month d-inline-block"
                type="text" 
                maxlength=2
                placeholder="MM"
                id="bdaymonth" 
                name="bdaymonth"
                required
                autofocus
                tabindex="5"
                @click="trackInput('dob-month')"            
                />
                <input
                v-model.trim="profileform.birthday.day" 
                class="form-control birthday-day d-inline-block"
                type="text" 
                maxlength=2
                placeholder="DD"
                id="bdayday" 
                name="bdayday"
                required
                autofocus
                tabindex="6" 
                @click="trackInput('dob-day')"            
                />            
                <input
                v-model.trim="profileform.birthday.year" 
                class="form-control birthday-year d-inline-block"
                type="text" 
                placeholder="YYYY"
                maxlength=4
                id="bdayyear" 
                name="bdayyear"
                required
                autofocus
                tabindex="7" 
                @click="trackInput('dob-year')"               
                />    
              </div>
              <div class="important-bubble d-inline-block">!</div>
              <div class="important-info d-inline-block">
                <p class="ps-light-weight">{{$t('signup.step2.birthdaydetails')}}</p>
              </div>

            </b-col>             
          </b-row>
        </form>           
          <br/><br/>                 
          <b-button pill variant="primary" @click="checkForm()" v-if="saving == false">
            <div class="continue-icon ps-icons"></div>
            {{$t('global.continue')}}
          </b-button>
          <b-button pill variant="secondary" v-else>
            <div class="loading-icon ps-icons"></div>
            {{$t('global.saving')}}
          </b-button>              
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AvatarsList from '@/components/avatars.vue' 

export default {
  name: "SignupStep2", 
  components: {
    AvatarsList
  },
  watch: {
    'userData': {
      handler (newVal) {
        if (newVal) { // check if master is already set.
          this.loadData();
          //TODO check on master profile
          /*
          let masterProfile = this.userData.profiles.find(p => {
            return p.type === 'owner'
          });
          if(masterProfile){
            this.$router.push('/signup/onboarding')      
          }*/          
        }
      },
      immediate: true // make this watch function is called when component created
    },
    'error': {
      handler (newVal) {
        if (newVal != null) { 
          //? not sure if this is ideal, but fine for now.
          this.tracking({
            type: 'event',
            category: 'master-profile',
            action: 'error',
          });   
        }
      },
      immediate: true 
    }    
  },    
  data() {
    return {
      profileform: {
        firstname: "Name",
        profilePhoto: '/img/avatars/unset_avatar.svg',
        parentalPin: null,
        birthday: {
          month: null,
          day: null,
          year: null
        },
        pin: {
          firstnumber: null,
          secondnumber: null,
          thirdnumber: null,
          forthnumber: null
        }
      },
      error: null,
      individualOnly: true,
      fullbirthday: null,
      trackingsection: "master-profile",
      saving: false
    };
  }, 
  computed: {
    ...mapState(['userData']),
    ...mapState(['guestData']),
  },    
  methods: {
    loadData() {
      this.profileform.firstname = this.userData.firstname;
      if(this.userData.type == 'individual'){
        this.individualOnly = true;
      } else {
        // force no PIN on all accounts
        this.individualOnly = true;      
      }   
    },
    checkForm() {
      this.saving = true;
      this.tracking({
        type: 'event',
        category: 'master-profile',
        action: 'save',
      });        
      //TODO check if user profile already exists in state.
      this.profileform.parentalPin = this.profileform.pin.firstnumber + this.profileform.pin.secondnumber + this.profileform.pin.thirdnumber + this.profileform.pin.forthnumber;
      if(!this.validYear(this.profileform.birthday.year)){
        this.error = this.$t('errors.invalidbirthday');
        this.saving = false;
        return;        
      }
      if(!this.profileform.birthday.month || !this.profileform.birthday.day || !this.profileform.birthday.year){
        this.error = this.$t('errors.birthday');
        this.saving = false;
        return;
      } else {
        this.fullbirthday = this.formatBirthday(this.profileform.birthday.month,this.profileform.birthday.day,this.profileform.birthday.year);
      } 
      if(!this.validDate(this.fullbirthday)){
        this.error = this.$t('errors.invalidbirthday');
        this.saving = false;
        return;         
      }
      if(!this.ageCheck(this.fullbirthday)){
        this.error = "You must be over 18 to setup an account, please get an adult to continue.";
        this.saving = false;
        return;         
      }      
      if(!this.profileform.profilePhoto){
        this.error = this.$t('errors.nophoto'); 
        this.saving = false;
        return;         
      }
      if(this.profileform.profilePhoto === '/img/avatars/unset_avatar.svg'){
        this.error = this.$t('errors.nophoto');
        this.savingAdd = false;
        this.saving = false;
        return;
      }      
      if(!this.individualOnly) {
        if(!this.profileform.parentalPin) {
          this.error = this.$t('errors.pinrequired');
          this.saving = false;
          return;  
        }
        //console.log(parseInt(this.profileform.parentalPin));
        if(this.validPIN(parseInt(this.profileform.parentalPin)) === false) {
          this.error = this.$t('errors.pinformat');
          this.saving = false;
          return;  
        }       
      }
      this.addProfile();             
    },
    changeAvatarPic(path){
      this.profileform.profilePhoto = path;
    },
    addProfile(){
      this.$store.dispatch('addProfile', {
        name: this.userData.firstname,
        profilePhoto: this.profileform.profilePhoto,
        birthday: this.fullbirthday,
        type: 'owner'
      }).then(async () => {
        await this.$store.commit('setActiveProfile', this.$store.getters.masterProfile);
        await this.$store.dispatch('hubspotUpdate');    
        if(!this.individualOnly) {
          this.setPIN(); 
        } else {
          this.$router.push('/signup/step3')
          /*
          if(this.guestData != null) {
            let inviteId = this.guestData.id;
            this.$router.push('/selectprofile/');
          } else {    
            this.$router.push('/home');
          }*/               
        }
      })
      .catch(error => {
        this.error = error.message;
        console.log(error);
        this.saving = false;
        //this.logError(error);
      })
    },
    setPIN(){
      this.$store.dispatch('addPIN', {
        parentalPin: this.profileform.parentalPin
      }).then(() => {
        /*
        if(this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {   
          this.$router.push('/signup/step3')
        } */ 
        this.$router.push('/signup/step3')
      })
      .catch(error => {
        this.error = error.message;
        console.log(error);
        this.saving = false;
        //this.logError(error);
      })
    },
     trackInput(label){
      this.tracking({
        type: 'event',
        category: 'master-profile',
        action: 'forminput',
        label: label
      });     
    },   
  },
  mounted (){
    //checks if they have a user profile in state
    this.loadData();
    this.tracking({
        type: 'page',
        title: '/signup/step2',
    });       
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';
#signup-step-2{
  .input-label {
    margin: 15px 0 0 0;
  }

  .important-info {
    width: 300px;
    margin: 0 0 0 10px;
    vertical-align: top;
  }
  p.lighter {
    font-weight: 400;
  }
  .input-wrapper {
    width: 180px;
    vertical-align: top;
  }
}
</style>