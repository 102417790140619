<template>
    <div id="beta-waitlist">
        <b-row align-h="start">      
            <b-col cols="10" md="7">  
                <h1>{{$t('betawaitlist.heading')}}</h1>
                <br/><br/>                
                <p>{{$t('betawaitlist.paragraph1')}}</p> 

                <p>{{$t('betawaitlist.paragraph2')}}</p> 

                <p>{{$t('betawaitlist.paragraph3')}} <a href="https://www.playspaces.co/blog" @click="trackLink('blog')" target="_blank">{{$t('global.blog')}}</a> {{$t('betawaitlist.paragraph4')}}</p>   

                <p>{{$t('betawaitlist.signature')}}</p>

            </b-col> 
            <b-col cols="2" md="5"> 
                <img src="/img/elements/welcome/waitlist_alien.svg" width="296" height="340" alt="Waitlist Alien" title="Waitlist Alien"/>
            </b-col>  
        </b-row> 
    </div>  
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: "Betawaitlist",
    computed: {
        ...mapState(['userData']),        
        ...mapState(['signUpCodeData']),
    },    
    data() {
        return {

            error: null,
            saving: false,

        };
    }, 
    mounted(){
        this.tracking({
            type: 'page',
            title: '/signup/waitlist',
        });   
    },       
    methods: {
        moreInfo() {
            this.tracking({
                type: 'event',
                category: 'betasignup-flow',
                action: 'betasignup:waitlistmoreinfo',
            });  
            window.open('https://www.playspaces.co/faq','_blank');     
        },
        trackLink(name){
            this.tracking({
                type: 'event',
                category: 'betawaitlist',
                action: 'external_link',
                label: name
            }); 
        },        
    }
}
</script>

<style lang="scss" scoped>  
#beta-waitlist {
    margin: 30px 0;
}
</style>