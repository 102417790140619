<template>
  <div id="login-block" class="general-modal">
    <b-row align-h="start">
      <b-col cols="12" md="12"> 
        <h2>{{$t('auth.login.heading')}}</h2>   
        <div v-if="error" class="error-msg">{{error}}</div>
        <div v-else><br/><br/></div>
        <form @submit.prevent>
          <label for="email">{{$t('global.email')}}</label>
          <input
          id="email"
          name="email"
          v-model.trim="loginform.email"
          type="email"
          maxlength=320
          class="form-control"
          required
          autofocus
          @click="trackInput('email')"
          />
          <label for="password">{{$t('global.password')}}</label>
          <br/>
          <input
          id="password"
          name="password"
          maxlength=100
          v-model.trim="loginform.password"
          type="password"
          class="form-control"
          required
          @click="trackInput('password')"
          />
          <p><a href="#" @click.prevent="forgot()">{{$t('auth.login.forgotpassword')}}</a></p>
          <br/><br/>
          <b-row align-v="center" align-h="start">
            <b-col > 
              <b-button pill variant="primary" @click="checkForm()" v-if="loading == false">
                <div class="login-icon ps-icons"></div>
                {{$t('global.login')}}
              </b-button>
              <b-button pill variant="secondary" v-else>
                <div class="loading-icon ps-icons"></div>
                {{$t('global.loading')}}
              </b-button>               
            </b-col>

          </b-row>     
        </form>
        <br/><br/>
        <p><a href="#" @click.prevent="signup()" >{{$t('global.signup')}}</a>{{$t('auth.login.foranaccount')}}</p>
        
      </b-col> 
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "login",
  computed: {
    ...mapState(['userData']),
    ...mapState(['guestData']),
  },   
  watch: {
    'error': {
      handler (newVal) {
        if (newVal != null) { 
          //? not sure if this is ideal, but fine for now.
          this.tracking({
            type: 'event',
            category: 'register-flow',
            action: 'login:error',
          });   
        }
      },
      immediate: true 
    }    
  },     
  data() {
    return {
      loginform: {
        email: '',
        password: '',
      },
      error: null,
      timezone: '',
      location: '',
      loading: false
    }
  },
  methods: {
    async login() {
      try {
        await this.getLocationData();
        await this.$store.dispatch('login', {
          email: this.loginform.email,
          password: this.loginform.password
        })
        .then((response) => {
          if(response != null) {
            this.error = response.message;
            this.logError(response.message);
            this.loading = false;
          } else {
            if(this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
              } else {
              if(this.guestData){
                let inviteId = this.guestData.id;
                this.$router.push('/selectprofile/');
              } else {
                this.$router.push('/home');
              }
            }
          }
        });
      } catch(error) {
          this.loading = false;
          this.error = error.message;
      }
    },
    checkForm() {
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'login:submit',
      }); 
      this.loading = true;       
      if(!this.loginform.email){
        this.error = this.$t('errors.emailandpassword');
        this.loading = false;
        return;
      } else if(!this.validEmail(this.loginform.email)){
        this.error = this.$t('errors.validemail');
        this.loading = false;
        return;
      } else if(!this.loginform.password){
        this.error = this.$t('errors.emailandpassword');
        this.loading = false;
        return;
      } else {
        this.login();
      }
      
    },
    trackInput(label){
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'login:forminput',
        label: label
      });     
    },
    forgot(){
      this.$router.push('/forgot');
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'login:forgotpassword',
      });   
    },
    signup(){
      this.$router.push('/')
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'login:signup',
      });         
    }
  },
  mounted(){
    // TODO fix bad password errors
    
    this.tracking({
        type: 'page',
        title: '/login',
    });    
  }  
}
</script>

<style lang="scss" scoped>
#login-block {
  text-align: left;
  max-width: 400px;
  min-width: 320px;
  max-height: 540px;
  padding: 60px 60px;  
}
</style>