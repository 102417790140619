<template>
    <div id="beta-confirmed">
        <b-row align-h="start">      
            <b-col cols="10" md="7">  
                <h1>{{$t('betaconfirmed.heading')}}</h1>
                <br/><br/>                      
                <p>{{$t('betaconfirmed.paragraph1')}}</p> 
                <br/>
                <p>{{$t('betaconfirmed.paragraph2')}}</p>
                <div v-if="error" class="error-msg">{{error}}</div>
                <br/><br/>        
                <b-button pill variant="primary" class="d-inline-block" @click="createAccount()">
                    <div class="continue-icon ps-icons"></div>
                    {{$t('global.continue')}}
                </b-button>  
            </b-col> 
            <b-col cols="2" md="5"> 
                <img src="/img/elements/welcome/beta_confirm.svg" width="296" height="356" alt="Beta Confirmed Alien" title="Beta Confirmed Alien"/>
            </b-col> 
        </b-row>
    </div>  
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "Betaconfirmed",
    computed: {
        ...mapState(['userData']),        
        ...mapState(['signUpCodeData']),
    },    
    data() {
        return {

            error: null,
            saving: false,

        };
    },    
    mounted(){
        this.tracking({
            type: 'page',
            title: '/signup/confirmed',
        });   
    },
    methods: {
        createAccount() {
            let invitecode = this.signUpCodeData.id
            this.tracking({
                type: 'event',
                category: 'betasignup-flow',
                action: 'betasignup:createaccount',
            });                   
            //this.$router.push('/join/beta/'+invitecode);
            this.$router.push('/agechecker');
        }
    }
}
</script>

<style lang="scss" scoped>  
#beta-confirmed {
    margin: 30px 0;
}
</style>