<template>
    <div id="signup-step-3">
        <h1>{{$t('signup.step3.heading')}}</h1>
        <p>{{$t('signup.step3.subheading')}}</p>
        <div v-if="error" class="error-msg">{{error}}</div>
        <div v-if="message" class="general-msg">{{message}}</div>
        <form>
          <div>
            <div class="input-firstname d-inline-block">
              <label>{{$t('signup.step3.firstname')}}</label>
              <input
              v-model.trim="profileform.name"
              id="name"
              name="name"
              type="name"
              class="form-control"
              maxlength=30
              required
              autofocus
              tabindex="1"   
              @click="trackInput('name')"   
              />             
            </div>
            <div class="input-birthday d-inline-block">
              <label >{{$t('signup.step3.birthday')}}</label><br/>
              <input
              v-model.trim="profileform.birthday.month" 
              class="form-control birthday-month d-inline-block"
              type="text" 
              maxlength=2
              placeholder="MM"
              id="bdaymonth" 
              name="bdaymonth"
              required
              autofocus
              tabindex="2"  
              @click="trackInput('dob-month')"             
              />
              <input
              v-model.trim="profileform.birthday.day" 
              class="form-control birthday-day d-inline-block"
              type="text" 
              maxlength=2
              placeholder="DD"
              id="bdayday" 
              name="bdayday"
              required
              autofocus
              tabindex="3" 
              @click="trackInput('dob-day')"            
              />            
              <input
              v-model.trim="profileform.birthday.year" 
              class="form-control birthday-year d-inline-block"
              type="text" 
              placeholder="YYYY"
              maxlength=4
              id="bdayyear" 
              name="bdayyear"
              required
              autofocus
              tabindex="4"   
              @click="trackInput('dob-year')"          
              /> 
            </div>  
            <div class="d-inline-block">
              <div class="d-inline-block align-top">
                  <div class="d-inline-block align-top"><label>{{$t('signup.step3.accesslevel')}}</label></div>
                  <div class="d-inline-block align-top"><div @click="openPermissionsModal()" class="permissions-info pointer important-bubble">!</div></div>
              </div>
              <br/>
             
              <div class="d-inline-block" >
                  <b-form-radio 
                  v-model.trim="profileform.type"
                  name="type"
                  class="radio-input" 
                  value="adult"
                  tabindex="5"    
                  size="lg"  
                  >
                  {{$t('signup.step3.adult')}}
                  </b-form-radio>                    
              </div>

              <div class="d-inline-block" >
                  <b-form-radio 
                  v-model.trim="profileform.type"
                  name="type"
                  class="radio-input"
                  value="kid"
                  tabindex="6"  
                  size="lg"
                  >
                  {{$t('signup.step3.kid')}}
                  </b-form-radio>                  
              </div>
              
            </div>                          
          </div>
        </form>
        <br/>
        <AvatarsList 
        :avatarPhoto="profileform.profilePhoto" 
        :firstName="profileform.name" 
        v-bind:section="trackingsection"
        @changeAvatar="changeAvatarPic($event)"/>
        <br/><br/><br/>
        <b-row align-h="start">
          <b-col v-if="profileCount < 6">
            <b-button pill variant="primary" @click="checkForm(false)" v-if="saving == false && savingAdd == false">
              <div class="save-icon ps-icons"></div>{{$t('global.save')}}
            </b-button>
            <b-button pill variant="secondary" v-if="saving == true">
              <div class="loading-icon ps-icons"></div>
              {{$t('global.saving')}}
            </b-button>
            <b-button pill variant="secondary" v-if="savingAdd == true">
              
            </b-button>                                 
          </b-col>
          <b-col v-if="profileCount < 5">  
            <b-button pill variant="primary" @click="checkForm(true)" v-if="savingAdd == false && saving == false">
              <div class="add-another-icon ps-icons"></div>{{$t('signup.step3.addanother')}}
            </b-button>
            <b-button pill variant="secondary"  v-if="savingAdd == true">
              <div class="loading-icon ps-icons"></div>
              {{$t('global.saving')}}
            </b-button>
            <b-button pill variant="secondary" v-if="saving == true">
              
            </b-button>                         
          </b-col>
          <b-col cols="5" >  
            <p class="button-right" v-if="saving == false && savingAdd == false">
              <a @click.prevent="skipNow()" href="#">{{$t('signup.step3.skipfornow')}}</a>
            </p>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import AvatarsList from '@/components/avatars.vue' 
import { mapState } from 'vuex'

export default {
  name: "Signupstep3", 
  components: {
    AvatarsList,
  },   
  computed: {
    ...mapState(['userData']),  
    ...mapState(['guestData']),
    ...mapState(['profiles']),
  },
  watch: {
      'profiles': {
        handler (newVal) {
          if (newVal) {
            this.profileCount = this.$store.getters.profileCount;
          }
        },
        immediate: true 
    },   
    profileform: {
      handler: function(newVal) { // watch it
        if(this.profileform.birthday.day != null && this.profileform.birthday.month != null && this.profileform.birthday.year != null) {
          if(this.ageCheck(this.profileform.birthday.year)){
            this.profileform.type = 'adult';
          } else {
            this.profileform.type = 'kid';         
          }
        }
      },
      deep: true
   },    
    error: function (newVal) {
      if (newVal != null) { 
        this.tracking({
          type: 'event',
          category: 'profiles',
          action: 'error',
        });   
      }
    }       
  },    
  data() {
    return {
      profileform: {
        profilePhoto: '/img/avatars/unset_avatar.svg',
        name: null,
        birthday: {
          month: null,
          day: null,
          year: null
        },
        type: null
      },
      oldval: {
        type: null
      },
      error: null,
      message: null,
      fullbirthday: null,
      profileCount: this.$store.getters.profileCount || 0,
      trackingsection: 'profiles',
      saving: false,
      savingAdd: false
    };
  },
  methods: {
    changeAvatarPic(path){
      this.profileform.profilePhoto = path;
    },
    openPermissionsModal(){
      this.$parent.$emit('openPermissionsModal');  
      this.tracking({
          type: 'event',
          category: 'profiles',
          action: 'infopopup'
      });              
    },   
    checkForm(another) {
      this.message = null;
      if(another === true) {
        this.savingAdd = true;               
      } else {
        this.saving = true;
      }  
      if(!this.validYear(this.profileform.birthday.year)){
        this.error = this.$t('errors.invalidbirthday');
        this.saving = false;
        return;        
      }            
      if(!this.profileform.birthday.month || !this.profileform.birthday.day || !this.profileform.birthday.year){
        this.error = this.$t('errors.birthday');
        this.savingAdd = false;
        this.saving = false;
        return;
      } else {
        this.fullbirthday = this.formatBirthday(this.profileform.birthday.month,this.profileform.birthday.day,this.profileform.birthday.year);
      } 
      if(!this.profileform.name){
        this.error = this.$t('errors.firstname');
        this.savingAdd = false;
        this.saving = false;
        return;
      }
      if(!this.profileform.profilePhoto){
        this.error = this.$t('errors.nophoto');
        this.savingAdd = false;
        this.saving = false;
        return;
      }
      if(this.profileform.profilePhoto === '/img/avatars/unset_avatar.svg'){
        this.error = this.$t('errors.nophoto');
        this.savingAdd = false;
        this.saving = false;
        return;
      }
      if(!this.validDate(this.fullbirthday)){
        this.error = this.$t('errors.invalidbirthday');
        this.savingAdd = false;
        this.saving = false;
        return;         
      }
      if(!this.profileform.type){
        this.error = this.$t('errors.typerequired');
        this.savingAdd = false;
        this.saving = false;
        return;
      }     
      if(this.profileform.type === 'kid') {
        this.tracking({
          type: 'event',
          category: 'profiles',
          action: 'forminput',
          label: 'type-kid'
        });
      } else if(this.profileform.type === 'adult') {
        this.tracking({
          type: 'event',
          category: 'profiles',
          action: 'forminput',
          label: 'type-adult'
        });
        
      }      
      if(another === true) {
        this.saveAnotherProfile()
          this.tracking({
            type: 'event',
            category: 'profiles',
            action: 'add-another',
          });               
      } else {
        this.saveProfile();
          this.tracking({
            type: 'event',
            category: 'profiles',
            action: 'save',
          });           
      }     
    },
    async addProfile(){
      await this.$store.dispatch('addProfile', {
        name: this.profileform.name,
        profilePhoto: this.profileform.profilePhoto,
        birthday: this.fullbirthday,
        type: this.profileform.type,
      })
      .catch(error => {
        this.error = error.message;
        this.logError(error);
        this.savingAdd = false;
        this.saving = false;
      })
    },
    saveProfile(){
      this.addProfile()
      if(this.guestData != null){
        let inviteId = this.guestData.id;
        this.$router.push('/selectprofile/');
      } else {
        this.$router.push('/signup/onboarding');
      }       
      //this.$router.push('/home')
    },
    async saveAnotherProfile(){
      await this.addProfile()
      .then(() => {
          this.savingAdd = false;
          this.saving = false;
          this.message = "Profile saved";
          this.error = null;
          this.profileform.birthday.day = null;
          this.profileform.birthday.month = null;
          this.profileform.birthday.year = null;
          this.profileform.profilePhoto = '/img/avatars/unset_avatar.svg';
          this.fullbirthday = null;
          this.profileform.name = null;
          this.profileform.type = null;          
      });
    },
    skipNow(){
      if(this.guestData != null){
        let inviteId = this.guestData.id;
        this.$router.push('/selectprofile/');
      } else {
        this.$router.push('/signup/onboarding');
      }
      this.tracking({
        type: 'event',
        category: 'profiles',
        action: 'skipfornow',
      });                  
    },
    trackInput(label){
      this.tracking({
        type: 'event',
        category: 'profiles',
        action: 'forminput',
        label: label
      });     
    },   
  },
  mounted () {
    //routes them home if they have 6 profiles
    this.tracking({
        type: 'page',
        title: '/signup/step3',
    });       
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';
#signup-step-3{
  position: relative;
  .radio-input{
    vertical-align: top;
    label {
      padding: 7px 0 0 0;
      margin: 0 10px 0 0;
    }
  }
  .permissions-info {
    margin: 0 0 0 8px;
    cursor: pointer;
  }
  .input-firstname {
    vertical-align: top;
    width: 180px;
    margin: 0 20px 0 0;
  }
  .input-birthday {
    vertical-align: top;
    width: 205px;
  }  
  .input-access {
    vertical-align: top;
    width: 200px;    
  }
  #back-button {
    position: absolute;
    left: -50px;
    top: 26px;
    z-index: 100;
  }
  p.button-right {
    padding: 8px 0 0 0;
  }
}
</style>