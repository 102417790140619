<template>
<div id="forgot-block" class="general-modal">
  <b-row align-h="start">
    <b-col cols="12" md="12">  
      <div v-if="complete === false">
          <h2>{{$t('auth.forgot.heading')}}</h2>
          <p>{{$t('auth.forgot.subheading')}}</p>
          <div v-if="error" class="error-msg">{{error}}</div>
          <br/><br/>
          <form @submit.prevent>
            <label for="email" class="">{{$t('global.email')}}</label>
            <input
            v-model.trim="forgotform.email"
            id="email"
            type="text"
            maxlength=320
            class="form-control"
            name="email"
            required
            autofocus
            />
            <br/><br/>
            <b-row align-v="center" align-h="start">
              <b-col>
                <b-button pill variant="primary" @click="checkForm()"><div class="submit-icon ps-icons"></div> {{$t('global.submit')}}</b-button>
              </b-col>
            </b-row>  
            <br/><br/>
            <b-row align-v="center" align-h="start">
              <b-col>
                <p class="button-right"><a href="#" @click.prevent="login()">{{$t('global.cancel')}}</a></p>
              </b-col>
            </b-row>
          </form>
      </div>
      <div v-if="complete === true">
        <h2>{{$t('auth.forgot.completeheading')}}{{this.forgotform.email}}!</h2>
        <p>{{$t('auth.forgot.completesubheading')}}</p>
        <br/>
        <br/>
        <router-link to="login" >
          <b-button pill variant="primary"><div class="back-icon ps-icons"></div>{{$t('auth.forgot.backtologin')}}</b-button>
        </router-link>
      </div>
    </b-col>
  </b-row>    
</div>
</template>

<script>

export default {
  name: "Forgot",  
   data() {
    return {
      forgotform: {
        email: '',
      },
      error: null,
      complete: false
    }
  },
  methods: {
    forgot() {
      this.$store.dispatch('forgotPassword', {
        email: this.forgotform.email,
      })
      .then(() => {
        this.complete = true;
      })
      .catch(error => {
        this.error = error.message;
        this.logError(error);
      }); 
    },
    checkForm() {
      if(!this.forgotform.email){
        this.error = this.$t('errors.email');
      } else if(!this.validEmail(this.forgotform.email)){
        this.error = this.$t('errors.validemail');
      } else {
        this.forgot();
      }
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'login:forgotpasswordsubmit',
      });        
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    login(){
      this.$router.push('/login');
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action: 'login:back',
      });        
    }
  },
  mounted(){
    this.tracking({
        type: 'page',
        title: '/forgotpassword',
    });        
  }
};
</script>

<style lang="scss" scoped>
#forgot-block {
  text-align: left;
  max-width: 400px;
  min-width: 320px;
  height: 540px;
  padding: 60px 60px;  
}
</style>